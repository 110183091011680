<template>
  <v-app>
    <v-sheet class="px-4" min-width="410px">
      <div>
        <div v-if="$vuetify.breakpoint.smAndUp">
          <v-img width="100%" position="relative" :src="subsForm.images" />
          <v-img
            v-if="showComingSoon(subsForm)"
            style="width: 100%;position: absolute; top: 0; left: 0"
            src="@/assets/images/logos/BimiStock-ComingsoonMark.png"
            contain
          />
        </div>
        <div v-if="!$vuetify.breakpoint.smAndUp">
          <v-img
            style="width: 100%;background-position: center; position: relative"
            :src="subsForm.images"
          />
          <v-img
            v-if="showComingSoon(subsForm)"
            style="width: 100%;position: absolute; top: 0; left: 0"
            src="@/assets/images/logos/BimiStock-ComingsoonMark.png"
            contain
          />
        </div>
        <div class="mb-12 pb-3" style="position: relative">
          <div
            v-if="$vuetify.breakpoint.smAndUp"
            style="
              margin-top: 25px;
              line-height: 80px;
              text-align: center;
              font-family: 'Noto Sans JP', sans-serif;
              font-style: normal;
              font-weight: 900;
              font-size: 55px;
              color: black;
            "
          >
            <!-- <span>シーズン{{ subsForm.subs_season }}</span>
          <br /> -->
            <span> {{ subsForm.subs_name }} </span>
            <br />
            <span class="ml-6" style="font-size: 50px">{{ subsForm.subs_tip.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') }}円（税込み）</span>
          </div>

          <div v-if="$vuetify.breakpoint.smAndUp">
            <div
              style="
                /* margin-top: 5%; */
                font-family: 'Noto Sans JP', sans-serif;
                font-weight: bold;
                color: #000;
                text-align: center;
                font-size: 35px;
              "
              class="mt-3"
            >
              <span>販売期間: </span>
              <span>{{ subsForm.subs_ssd }}〜{{ subsForm.subs_sed }}</span>
            </div>
            <div style="margin: 25px 5% 0; font-family: 'Noto Sans JP', sans-serif; font-size: 20px; font-weight: bold">
              <div v-html="subsForm.subs_overview"></div>
            </div>
          </div>
          <div
            v-if="!$vuetify.breakpoint.smAndUp"
            style="
              margin-top: 25px;
              line-height: 70px;
              text-align: center;
              font-family: 'Noto Sans JP', sans-serif;
              font-style: normal;
              font-weight: 900;
              font-size: 40px;
              color: black;
            "
          >
            <!-- <span>シーズン{{ subsForm.subs_season }}</span>
          <br /> -->
            <span> {{ subsForm.subs_name }} </span>
            <br />
            <span class="ml-6" style="font-size: 35px">{{ subsForm.subs_tip.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') }}円（税込み）</span>
          </div>
          <div v-if="!$vuetify.breakpoint.smAndUp">
            <div
              style="
                /* margin-top: 5%; */
                font-family: 'Noto Sans JP', sans-serif;
                font-weight: bold;
                color: #000;
                text-align: center;
                font-size: 35px;
              "
              class="mt-3"
            >
              <span>販売期間: </span>
              <span>{{ subsForm.subs_ssd }}〜{{ subsForm.subs_sed }}</span>
            </div>
            <div style="margin: 25px 5% 0; font-family: 'Noto Sans JP', sans-serif; font-size: 16px; font-weight: bold">
              <div v-html="subsForm.subs_overview"></div>
            </div>
          </div>
          <div
            v-if="$vuetify.breakpoint.smOnly"
            style="
              position: absolute;
              width: 650px;
              height: 600px;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              text-align: center;
            "
          >
            <span
              style="
                opacity: 0.08;
                line-height: 150px;
                white-space: nowrap;
                font-family: Helvetica;
                font-weight: bold;
                font-size: 125px;
                color: black;
              "
            >Delicious<br />Preservative<br />Foods</span>
          </div>
          <div
            v-if="$vuetify.breakpoint.mdOnly"
            style="
              position: absolute;
              width: 1000px;
              height: 700px;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              text-align: center;
            "
          >
            <span
              style="
                opacity: 0.08;
                line-height: 250px;
                white-space: nowrap;
                font-family: Helvetica;
                font-weight: bold;
                font-size: 165px;
                color: black;
              "
            >Delicious<br />Preservative<br />Foods</span>
          </div>
          <div
            v-if="$vuetify.breakpoint.lgAndUp"
            style="
              position: absolute;
              width: 1366px;
              height: 837px;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              text-align: center;
            "
          >
            <span
              style="
                opacity: 0.08;
                line-height: 300px;
                white-space: nowrap;
                font-family: Helvetica;
                font-weight: bold;
                font-size: 237px;
                color: black;
              "
            >Delicious<br />Preservative<br />Foods</span>
          </div>
          <div v-if="!$vuetify.breakpoint.mdAndUp">
            <v-row class="mt-6" no-gutter>
              <v-spacer></v-spacer>
              <v-col v-for="(item, index) in mobileList" :key="index" cols="6" sm="4" md="3" class="pa-5 pointer">
                <v-img
                  alt="BimiStock（ビミストック）は全国の美味しい保存食を3か月毎お届けする普段使いの防災食定期便です。"
                  :src="item.image"
                  @click="openForm(item.id)"
                ></v-img>
                <div class="text" style="white-space: pre-line">
                  <span>{{ item.item_name }}</span>
                </div>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <div
              v-if="!showComingSoon(subsForm)"
              style="margin: auto; width: 374.5px; height: 120px"
              class="d-flex justify-center mt-12"
            >
              <v-btn
                depressed
                rounded
                style="background-color: #ff6e40; width: 60%; min-height: 80px"
                @click="addToCart"
              >
                <div
                  style="
                    color: #fff;
                    line-height: 37px;
                    font-family: 'Noto Sans JP', sans-serif;
                    font-style: normal;
                    font-weight: 900;
                    font-size: 20px;
                  "
                >
                  <span>購入する</span>
                </div>
              </v-btn>
            </div>
            <!-- <div v-else style="text-align: center">
            <img src="@/assets/images/logos/comingSoon.jpg" style="fit-object: contain" width="50%" />
          </div> -->
          </div>
          <div v-if="$vuetify.breakpoint.mdAndUp" style="max-width: 1366px; margin: auto" class="mt-12">
            <v-row v-for="(line, index) in subsList" :key="index">
              <v-spacer></v-spacer>
              <v-col v-for="(item, index) in line" :key="index" cols="6" md="3" class="pa-5 pointer">
                <v-img
                  alt="BimiStock（ビミストック）は全国の美味しい保存食を3か月毎お届けする普段使いの防災食定期便です。"
                  :src="item.image"
                  @click="openForm(item.id)"
                ></v-img>
                <div class="text" style="white-space: pre-line">
                  <span>{{ item.item_name }}</span>
                </div>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <div v-if="!showComingSoon(subsForm)" class="d-flex justify-center my-12">
              <v-btn
                depressed
                rounded
                style="background-color: #ff6e40; width: 300px; min-height: 120px"
                @click="addToCart"
              >
                <div
                  style="
                    color: #fff;
                    line-height: 47px;
                    font-family: 'Noto Sans JP', sans-serif;
                    font-style: normal;
                    font-weight: 900;
                    font-size: 35px;
                  "
                >
                  <span>購入する</span>
                </div>
              </v-btn>
            </div>
            <!-- <div v-else style="text-align: center">
            <img src="@/assets/images/logos/comingSoon.jpg" style="fit-object: contain" width="50%" />
          </div> -->
          </div>
          <!-- <div
          v-if="!$vuetify.breakpoint.smAndUp"
          style="text-align: center; font-family: 'Noto Sans JP', sans-serif; font-style: normal; font-weight: 900"
        >
          <span style="font-size: 40px; color: black; line-height: 100px">次回のお届け商品</span>
          <br />
          <span style="font-size: 27px; color: black">{{ bottomYear }}年{{ bottomMonth }}月末までに</span>
          <br />
          <span style="font-size: 27px; color: black">「シーズン{{ bottomSeason }}」の商品を<br />ご案内致します。</span>
          <br />
          <span style="font-size: 27px; color: black">どうぞお楽しみにお待ち<br />ください。</span>
        </div>
        <div
          v-if="$vuetify.breakpoint.smOnly" -->
          <div
            v-if="!$vuetify.breakpoint.mdAndUp"
            style="text-align: center; font-family: 'Noto Sans JP', sans-serif; font-style: normal; font-weight: 900"
          >
            <div v-if="Object.keys(nextSeasonSubsList).length !== 0">
              <span
                style="
                  margin-top: 25px;
                  line-height: 70px;
                  text-align: center;
                  font-family: 'Noto Sans JP', sans-serif;
                  font-style: normal;
                  font-weight: 900;
                  font-size: 40px;
                  color: black;
                "
              >
                {{ nextSeasonSubs.subs_name }}
              </span>
              <br />
              <div
                style="
                  /* margin-top: 5%; */
                  font-family: 'Noto Sans JP', sans-serif;
                  font-weight: bold;
                  color: #000;
                  text-align: center;
                  font-size: 35px;
                "
                class="mt-3"
              >
                <span>販売期間: </span>
                <span>{{ nextSeasonSubs.subs_ssd }}〜{{ nextSeasonSubs.subs_sed }}</span>
              </div>
              <div
                style="
                  margin: 25px 5% 0;
                  font-family: 'Noto Sans JP', sans-serif;
                  font-size: 16px;
                  font-weight: bold;
                  text-align: start;
                "
              >
                <div v-html="nextSeasonSubs.subs_overview"></div>
              </div>
              <v-row class="mt-6" no-gutter>
                <v-spacer></v-spacer>
                <v-col
                  v-for="(item, index) in nextSeasonListMobile"
                  :key="index"
                  cols="6"
                  sm="4"
                  md="3"
                  class="pa-5 pointer"
                >
                  <v-img
                    alt="BimiStock（ビミストック）は全国の美味しい保存食を3か月毎お届けする普段使いの防災食定期便です。"
                    :src="item.image"
                    @click="openForm(item.id)"
                  ></v-img>
                  <div class="text" style="white-space: pre-line">
                    <span>{{ item.item_name }}</span>
                  </div>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </div>
          </div>
          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            style="text-align: center; font-family: 'Noto Sans JP', sans-serif; font-style: normal; font-weight: 900"
          >
            <div v-if="Object.keys(nextSeasonSubsList).length !== 0">
              <!-- <span style="font-size: 80px; color: black; line-height: 200px">次回のお届け商品</span> -->
              <span
                style="
                  margin-top: 25px;
                  line-height: 80px;
                  text-align: center;
                  font-family: 'Noto Sans JP', sans-serif;
                  font-style: normal;
                  font-weight: 900;
                  font-size: 55px;
                  color: black;
                "
              >{{ nextSeasonSubs.subs_name }}</span>
              <br />
              <div
                style="
                  /* margin-top: 5%; */
                  font-family: 'Noto Sans JP', sans-serif;
                  font-weight: bold;
                  color: #000;
                  text-align: center;
                  font-size: 35px;
                "
                class="mt-3"
              >
                <span>販売期間: </span>
                <span>{{ nextSeasonSubs.subs_ssd }}〜{{ nextSeasonSubs.subs_sed }}</span>
              </div>
              <div
                style="
                  margin: 25px 5% 0;
                  font-family: 'Noto Sans JP', sans-serif;
                  font-size: 20px;
                  font-weight: bold;
                  text-align: start;
                "
              >
                <div v-html="nextSeasonSubs.subs_overview"></div>
              </div>
              <div v-if="$vuetify.breakpoint.mdAndUp" style="max-width: 1366px; margin: auto" class="mt-12">
                <v-row v-for="(line, index) in nextSeasonSubsList" :key="index">
                  <v-spacer></v-spacer>
                  <v-col v-for="(item, index) in line" :key="index" cols="6" md="3" class="pa-5 pointer">
                    <v-img
                      alt="BimiStock（ビミストック）は全国の美味しい保存食を3か月毎お届けする普段使いの防災食定期便です。"
                      :src="item.image"
                      @click="openForm(item.id)"
                    ></v-img>
                    <div class="text" style="white-space: pre-line">
                      <span>{{ item.item_name }}</span>
                    </div>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </div>
            </div>
          </div>
        </div>
        <v-dialog v-model="dialog" class="previewDialog" :height="dialogHeight" scrollable max-width="900px">
          <v-card tile class="pt-6">
            <Overlays :is-absolute="true" :is-show="absoluteOverlayStatus"></Overlays>
            <v-card class="overflow-y-auto px-5" height="100%" tile flat>
              <v-container class="pa-5">
                <v-row no-gutters>
                  <v-col class="pa-1" cols="12" md="6">
                    <img id="mainImg" :class="mainImgClass" :src="mainImg" @load="loadImg" />
                  </v-col>
                  <v-col class="rightArea d-flex align-start flex-column pa-1" cols="12" md="6">
                    <span id="titleArea" :class="`${titleAreaClass} mb-3`">{{ item.item_name }}</span>
                    <!-- <div :style="`height:${overviewHeight}px !important`" class="itemOversize" v-html="ItemOverview"></div> -->
                    <div
                      :style="`height:${overviewHeight}px !important;text-align:center;width:${overviewMaxWidth}px`"
                      class="itemOverview"
                      v-html="item.item_overview"
                    ></div>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class="pa-1" cols="12">
                    <v-card class="mt-10" flat tile>
                      <div style="`font-size:${descFontSize}%;`" class="desc" v-html="item.item_desc"></div>
                    </v-card>
                  </v-col>
                </v-row>
                <div
                  style="
                  color: #ff6e40;
                  line-height: 35px;
                  font-family: 'Noto Sans JP', sans-serif;
                  font-style: normal;
                  font-weight: 900;
                  font-size: 15px;
                  cursor: pointer;
                "
                  class="mb-12"
                  @click="closeForm"
                >
                  <span> ▶︎一覧に戻る </span>
                </div>
              </v-container>
            </v-card>
          </v-card>
        </v-dialog>
        <v-dialog v-model="Tip" width="500px" persistent>
          <v-card height="180px" class="d-flex align-center justify-center">
            <div>
              <div class="mt-8" style="text-align: center; font-weight: bold; font-size: 20px">
                <span> 一回の注文につき最大２０個まで購入可能です。 </span>
              </div>
              <div class="d-flex justify-center mt-8">
                <v-btn color="#ff6e40" width="80px" depressed @click="Tip = false">
                  <span style="color: #fff">OK</span>
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </v-sheet>
  </v-app>
</template>

<script>
import {
  mdiCart, mdiClose,
} from '@mdi/js'
import {
  mapMutations, mapActions, mapState,
} from 'vuex'
import Overlays from '@/components/Overlays.vue'

export default {
  components: {
    Overlays,
  },
  data: () => ({
    icons: {
      mdiCart,
      mdiClose,
    },
    Tip: false,
    size: 0,
    imgHeight: '',
    overviewHeight: '',
    overviewMaxWidth: '',
    dialog: false,
    images: [],
    imgExist: false,
    mainImg: '',
    mainImgClass: '',
    titleAreaClass: '',
    titleFontSize: '',
    item: {
      description: {
        item_desc: '',
      },
      overview: {
        item_overview: '',
      },
    },
    nextSeasonSubs: null,
    mobileList: [],
    subsList: [],
    nextSeasonListMobile: [],
    nextSeasonSubsList: [],
    bottomYear: '',
    bottomMonth: '',
    bottomSeason: '',

    selectedImg: '',
    Form: {
      id: '',
      load: '',
      name: '',
      price_normal: '',
      count: 0,
    },
    subsForm: {
      subs_code: '',
      subs_name: '',
      subs_images: '',
      subs_tip: '',
    },
  }),
  computed: {
    ...mapState('cartStore', ['number', 'count', 'cartList']),
    ...mapState('subscriptionStore', ['selectedSubscription', 'subscriptionItems']),
    ...mapState('app', ['absoluteOverlayStatus']),
    ...mapState('itemStore', ['selectedItem', 'itemList']),
    ...mapState('auth', ['isLoggedIn']),
    showComingSoon() {
      return function (subscription) {
        return Date.now() < Date.parse(subscription.subs_ssd)
      }
    },

    descFontSize() {
      const device = this.$vuetify.breakpoint.name
      if (device === 'xs') return 75
      if (device === 'sm') return 100
      if (device === 'md') return 125
      if (device === 'lg') return 125

      return 125
    },
    dialogHeight() {
      return document.documentElement.clientHeight * 0.9
    },
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.clearDialogData()
      }
    },
  },
  created() {
    this.loadData()
  },
  mounted() {
    window.addEventListener('resize', this.resizeImg)
    window.addEventListener('load', this.getSize())
    window.addEventListener('resize', this.getSize)
  },
  methods: {
    ...mapMutations('cartStore', ['increNavCount', 'addProduct']),
    ...mapActions('subscriptionStore', ['loadSubscription', 'loadSubscriptionItems']),
    ...mapMutations('app', ['setAbsoluteOverlayStatus', 'setOverlayStatus']),
    ...mapActions('itemStore', ['loadItem', 'loadItemList']),

    getSize() {
      if (
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
        )
      ) {
        this.size = 1
      }
    },

    openForm(value) {
      this.dialog = true
      this.mainImgClass = `mianImg${this.id}`
      this.titleAreaClass = `titleArea${this.id}`
      this.loadDialog(value)
    },
    closeForm() {
      this.dialog = false
    },
    clearDialogData() {
      this.mainImg = ''
      this.item = {
        description: {
          item_desc: '',
        },
        overview: {
          item_overview: '',
        },
      }
      this.imgExist = false
    },

    resizeImg() {
      if (this.imgExist) {
        this.imgHeight = document.querySelector(`.${this.mainImgClass}`).offsetHeight
        if (this.$vuetify.breakpoint.name === 'xs') this.titleFontSize = 125
        if (this.$vuetify.breakpoint.name === 'sm') {
          this.titleFontSize = 150
        } else {
          this.titleFontSize = 175
        }
        document.querySelector(`.${this.titleAreaClass}`).style = `font-size:${this.titleFontSize}%;`
        const titleHeight = document.querySelector(`.${this.titleAreaClass}`).offsetHeight
        this.overviewHeight = this.imgHeight - titleHeight - 12
        const imgWidth = document.querySelector(`.${this.mainImgClass}`).offsetWidth
        this.overviewMaxWidth = imgWidth
      }
    },
    loadImg() {
      this.imgExist = true
      this.resizeImg()
    },

    loadDialog(value) {
      this.setAbsoluteOverlayStatus(true)
      this.loadItem(value).then(() => {
        this.item = {
          ...this.selectedItem,
        }
        console.log('loadItem', this.selectedItem)

        if (this.item.images) {
          this.mainImg = `${this.item.imgUrl_prefix}/${this.item.images.split('"')[1].replace(/^\//g, '')}`
        } else {
          this.mainImg = require('@/assets/images0116/pc/221122_bimistock_image_12l.png')
        }

        this.setAbsoluteOverlayStatus(false)
      })
    },

    loadData() {
      this.setOverlayStatus(true)
      this.loadSubscription(this.$route.params.id).then(() => {
        console.log('loadSubscription', this.selectedSubscription)
        this.subsForm = {
          ...this.selectedSubscription.current_season,
        }
        if (this.subsForm.subs_images !== null) {
          this.subsForm.images = `${this.subsForm.imgUrl_prefix}/${this.subsForm.subs_images
            .split('"')[1]
            .replace(/^\//g, '')}`
        } else {
          this.subsForm.images = require('@/assets/images0116/pc/221122_bimistock_image_12l.png')
        }
        if (this.subsForm.subs_season === 4) {
          this.bottomSeason = 1
          this.bottomMonth = 4
          this.bottomYear = this.subsForm.subs_year
        }
        if (this.subsForm.subs_season === 3) {
          this.bottomSeason = 4
          this.bottomMonth = 1
          this.bottomYear = this.subsForm.subs_year + 1
        }
        if (this.subsForm.subs_season === 2) {
          this.bottomSeason = 3
          this.bottomMonth = 10
          this.bottomYear = this.subsForm.subs_year
        }
        if (this.subsForm.subs_season === 1) {
          this.bottomSeason = 2
          this.bottomMonth = 7
          this.bottomYear = this.subsForm.subs_year
        }

        // loadSubscriptionItems
        const Items = [...this.subsForm.subs_details]
        Items.forEach((item, i) => {
          if (item.images) {
            Items[i].image = `${item.imgUrl_prefix}/${item.images
              .replaceAll('"', '')
              .split(',')[0]
              .replace(/^\//g, '')}`
          }
        })
        this.mobileList = [...Items]
        this.mobileList.forEach((ml, i) => {
          if (ml.item_name) {
            this.mobileList[i].item_name = this.mobileList[i].item_name.replace(/\s/g, '\n')
          }
        })
        const showStyle = this.subsForm.show_style
        if (showStyle) {
          const len = showStyle.length
          for (let i = 0; i < len; i += 1) {
            this.subsList.push(Items.splice(0, showStyle[i]))
          }
        } else {
          this.subsList.push(Items)
        }
        console.log('subsList', this.subsList)

        // next season
        this.nextSeasonSubs = {
          ...this.selectedSubscription.next_season,
        }
        console.log('this.nextSeasonSubs', this.nextSeasonSubs, Object.keys(this.nextSeasonSubs).length)
        if (Object.keys(this.nextSeasonSubs).length !== 0) {
          const nextSeasonDetails = this.nextSeasonSubs.subs_details
          console.log('nextSeasonDetails', this.nextSeasonSubs.subs_details[0])
          if (nextSeasonDetails) {
            nextSeasonDetails.forEach((ned, i) => {
              if (ned.images) {
                nextSeasonDetails[i].image = `${ned.imgUrl_prefix}/${ned.images
                  .replaceAll('"', '')
                  .split(',')[0]
                  .replace(/^\//g, '')}`
              }
            })
          }
          this.nextSeasonListMobile = [...nextSeasonDetails]
          this.nextSeasonListMobile.forEach((n, i) => {
            if (n.item_name) {
              this.nextSeasonListMobile[i].item_name = this.nextSeasonListMobile[i].item_name.replace(/\s/g, '\n')
            }
          })

          if (this.nextSeasonSubs.show_style) {
            const len = this.nextSeasonSubs.show_style.length
            for (let i = 0; i < len; i += 1) {
              this.nextSeasonSubsList.push(nextSeasonDetails.splice(0, this.nextSeasonSubs.show_style[i]))
            }
          } else {
            this.nextSeasonSubsList.push(nextSeasonDetails)
          }
        }

        // this.subsList1 = Items.filter(tl => tl.id > 125 && tl.id < 134).sort((a, b) => a.id - b.id)
        // this.subsList2 = Items.filter(tl => tl.id > 133 && tl.id < 137).sort((a, b) => a.id - b.id)
        // this.subsList3 = Items.filter(tl => tl.id > 136 && tl.id < 140).sort((a, b) => a.id - b.id)
        // this.mobileList = Items.filter(tl => tl.id > 125 && tl.id < 140).sort((a, b) => a.id - b.id)
      }).catch(() => {
        console.log('Can not find the subscription:', this.$route.params.id)
        this.$router.push('/notexists')
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    addSubscription() {
      const Form = {
      }
      Form.product_code = this.subsForm.subs_code
      if (this.subsForm.subs_images !== null) {
        Form.load = `${this.subsForm.imgUrl_prefix}/${this.subsForm.subs_images.split('"')[1].replace(/^\//g, '')}`
      } else {
        Form.load = require('@/assets/images/logos/NoImage.png')
      }
      Form.name = this.subsForm.subs_name
      Form.price_normal = this.subsForm.subs_tip
      Form.count = this.number
      this.addProduct(Form)
      this.increNavCount(this.number)
      this.$router.push('/cart')
    },
    addToCart() {
      if (this.isLoggedIn === true) {
        const index = this.cartList.findIndex(obj => obj.product_code === this.subsForm.subs_code)
        if (index !== -1) {
          const countCheck = this.cartList[index].count
          if (countCheck === 20) {
            this.Tip = true
          } else {
            this.addSubscription()
          }
        } else {
          this.addSubscription()
        }
      }
      if (this.isLoggedIn === false) {
        this.$router.push('/login')
      }
    },
  },
}
</script>

<style scoped>
.shippingfee {
  color: #ff6e40;
}
.shippingfee :hover {
  cursor: pointer;
}
.text {
  word-break: keep-all;
  margin-top: 8px;
  line-height: 20px;
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: black;
}

@media screen and (max-width: 721px) {
  .text {
    word-break: break-all;
  }
}
/* @media screen and (max-width: 720px) {
  .text {
    word-break: break-word;
  }
} */
.pointer :hover {
  cursor: pointer;
}

#mainImg {
  width: 100%;
  object-fit: contain;
}
#titleArea {
  /* font-size: 28px; */
  font-weight: 900;
  font-family: 'Noto Sans JP', sans-serif;
}
.desc {
  /* font-size: 20px; */
  font-family: 'Noto Sans JP', sans-serif;
  line-height: 45px;
}
.itemOverview >>> p {
  height: 100%;
  margin-bottom: 0;
}
.itemOverview >>> img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.v-toolbar >>> .v-toolbar__content {
  width: 100% !important;
}
</style>
